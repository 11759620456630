import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';

import SectionMedia from '../SectionMedia';
import ButtonEditor from '../../ButtonEditor/index';
import styles from './styles.module.css';

class StoryLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const index = props.section.data.length - 1;
    this.state = {
      section: props.section,
      layout: props.section.data[index].layout,
      full: props.section.data[index].full,
    };

  }

  render() {

    const isFull = this.state.layout === 'fullLeft' || this.state.layout === 'fullRight';

    let imageWrapper = 'imageWrapper';
    let sectionMediaWrapper = 'imageContent';
    if (isFull === true) {

      imageWrapper = 'imageWrapperFull';
      sectionMediaWrapper = 'imageContent4';

    } else if (this.state.section.data[3].data.icon) {

      imageWrapper = '';
      sectionMediaWrapper = '';

    }

    let leftWrapper = 'storyLeftWrapper';
    let rightWrapper = this.state.section.data[3].data.icon ? '' : styles.storyRightWrapperCenter;
    let contentWrapper = '';
    let headingStyle = 'storyHeader';
    let paragraphStyle = '';
    let btnStyle = 'storyBtnWrapper';
    let colLeft;
    let colRight;
    let rowWrapper = '';

    if (this.state.layout === 'left') {

      colLeft = 'col-12 col-md-7 second';
      colRight = 'col-12 col-md-5 first';

    } else if (this.state.layout === 'center') {

      leftWrapper = 'storyLeftWrapperCenter';
      headingStyle = 'storyHeaderCenter';
      paragraphStyle = 'storyParagraphCenter';
      btnStyle = 'storyBtnWrapperCenter';
      colLeft = 'col-12 col-md-8';
      colRight = 'col-12 col-md-5';
      rowWrapper = styles.storyRowWrapperCenter;

    } else if (this.state.layout === 'right') {

      colLeft = 'col-12 col-md-7';
      colRight = 'col-12 col-md-5';
      rowWrapper = styles.storyRowWrapper;

    } else if (this.state.layout === 'fullLeft') {

      colLeft = 'col-12 col-md-6';
      colRight = 'col-12 col-md-6';
      leftWrapper = 'storyWrapperFullLeft';
      rightWrapper = 'storyWrapperFull';
      contentWrapper = 'contentWrapper';

    } else if (this.state.layout === 'fullRight') {

      colLeft = 'col-12 col-md-6';
      colRight = 'col-12 col-md-6';
      leftWrapper = 'storyWrapperFullLeft';
      rightWrapper = 'storyWrapperFull';
      rowWrapper = styles.storyRowWrapper;
      contentWrapper = 'contentWrapper';

    }

    const left = [];
    this.state.section.data.map((item) => {

      if (item.type !== 'STYLE' && item.type !== 'IMAGES/IMAGE') {

        left.push(item);

      }

      return null;

    });

    if (isFull === true) {

      const tmp = left[1];
      left[1] = left[0];
      left[0] = tmp;

    }

    return (
      <div className={isFull && this.state.full ? undefined : 'container'}>
        <div className={`row ${rowWrapper}`} style={{ margin: 0 }}>
          <div className={`${colRight} ${styles[rightWrapper]}`}>
            {
              this.state.section.data[3].active &&
              (
                <div className={styles[imageWrapper]}>
                  <SectionMedia
                    mediaType={this.state.section.data[3].data.icon ? 'ICON' : 'IMAGE'}
                    wrapperStyle={sectionMediaWrapper}
                    elementStyle={isFull ? 'imageFull' : 'storyImage'}
                    iconStyle=""
                    src={this.state.section.data[3].data.src}
                    alt={this.state.section.data[3].data.alt}
                    data={this.state.section.data[3].data}
                    images={this.props.images}
                  />
                </div>
              )
            }
          </div>
          <div className={`${colLeft} ${styles[leftWrapper]}`}>
            <div className={styles[contentWrapper]}>
              {
                left.map((item, index) => {

                  if (item.active) {

                    let text;
                    let type;
                    if (item.type.startsWith('HEADINGS')) {

                      type = 'heading';
                      if (item.type === 'HEADINGS/HEADING-TWO') {

                        text = `<h2>${item.data}</h2>`;

                      } else if (item.type === 'HEADINGS/HEADING-FOUR') {

                        text = `<h4>${item.data}</h4>`;

                      }

                    } else if (item.type.startsWith('PARAGRAPH')) {

                      text = `<span>${item.data}</span>`;
                      type = 'paragraph';

                    }

                    if (text) {

                      return (
                        <div
                          key={`${this.state.section._id}_layout_${index}`}
                          className={
                            `${type === 'heading' ? styles[headingStyle] : styles[paragraphStyle]} ${isFull ? 'storyFull' : ''}`
                          }
                        >
                          {HTMLParser(text)}
                        </div>

                      );

                    } else if (item.type.startsWith('BUTTON')) {

                      return (
                        <div
                          key={`${this.state.section._id}_layout_${index}`}
                          className={styles[btnStyle]}
                        >
                          <ButtonEditor
                            themeData={this.props.themeData}
                            button="Secondary"
                            data={item.data}
                          />
                        </div>
                      );

                    }

                  }

                  return null;

                })
              }
            </div>
          </div>
        </div>
      </div>
    );

  }

}

StoryLayout.propTypes = {
  themeData: PropTypes.shape({}),
  section: PropTypes.shape({}),
};

export default CSSModules(StoryLayout, styles, { allowMultiple: true });
