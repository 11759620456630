import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../helper';
import SectionMedia from '../SectionMedia';
import styles from './styles.module.css';

class MenuLayout extends React.Component {

  constructor(props) {

    super(props);

    const index = this.props.section.data.length - 1;
    const align = this.props.section.data[index].align !== undefined ? this.props.section.data[index].align : 'Left';
    const full = this.props.section.data[index].full === true ? 'Full' : '';

    this.state = {
      section: props.section,
      full,
      align,
    };

    this.createContent = this.createContent.bind(this);

  }

  createContent(item, index) {

    let style;
    if (item.styles.backgroundColor.active) {

      let { backgroundColor } = item.styles;
      if (
        item.styles.backgroundColor.solid === '' &&
        item.styles.backgroundColor.gradient.from === ''
      ) {

        backgroundColor = { solid: '#ffffff' };

      }

      style = formColor(
        backgroundColor,
        false,
        item.styles.backgroundColor.opacity,
      );

    }

    if (this.props.layout === 'design2') style = { ...style, ...{ paddingBottom: '32px' } };
    const align = `align${item.align}`;
    const cols = this.props.layout === 'design2'
      ? 'col-12'
      : 'col-12 col-sm-8 offset-sm-2 col-md-5 offset-md-1 col-lg-4 offset-lg-0';
    const design = this.props.layout === 'design2' ? 'Design2' : '';

    const content = (
      <div
        key={`${this.props.section._id}_layout_${index}`}
        className={`${cols} ${styles[`menuComponentWrapper${design}`]}`}
      >
        <div style={style}>
          {
            item.data[0].active
            && (
              <div className={styles[`menuImageWrapper${design}`]}>
                <SectionMedia
                  id={this.state.section.data[index].data[0].data.id}
                  mediaType={(this.state.section.data[index].data[0].data.icon) ? 'ICON' : 'IMAGE'}
                  wrapperStyle="imageContent"
                  elementStyle={this.props.layout !== 'design2' ? 'menuImage' : 'imageFull'}
                  align={item.align}
                  iconStyle=""
                  src={`${this.props.section.data[index].data[0].data.src}`}
                  alt={this.state.section.data[index].data[0].data.alt}
                  data={this.state.section.data[index].data[0].data}
                  images={this.props.images}
                />
              </div>
            )
          }
          <div style={this.props.layout === 'left' && style ? { padding: '20px 10px' } : undefined}>
            {
              item.data[1].active
              && (
                <div className={`${styles[`menuComponentText${design}`]} ${styles[`${align}`]}`}>
                  { HTMLParser(`<h3>${item.data[1].data}</h3>`) }
                </div>
              )
            }
            {
              item.data[2].active
              && (
                <div className={`${styles[`menuComponentText${design}`]} ${styles[`${align}`]}`}>
                  { HTMLParser(`<span>${item.data[2].data}</span>`) }
                </div>
              )
            }
            {
              item.data[3].active
              && (
                <div className={`${styles[`menuComponentText${design}`]} ${styles[`${align}`]}`}>
                  { HTMLParser(`<h4>${item.data[3].data}</h4>`) }
                </div>
              )
            }
          </div>
        </div>
      </div>
    );

    return content;

  }

  render() {

    const activeComponents = [];
    this.props.section.data.map((item, index) => {

      if (item.type === 'COMPONENT' && item.active) {

        const content = this.createContent(item, index);
        activeComponents.push(content);

      }

      return null;

    });


    const alignStyle = `align${this.state.align}`;

    return (
      <div className={`container ${styles[`menuContainer${this.state.full}`]}`}>
        <div className={`row ${styles[alignStyle]}`}>
          <div className={`col-12 ${styles.menuMainHeader}`}>
            {
              this.props.section.data[0].active
              && (HTMLParser(`<h2>${this.props.section.data[0].data}</h2>`))
            }
          </div>
          { activeComponents }
        </div>
      </div>
    );

  }

}

MenuLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      align: PropTypes.string,
      full: PropTypes.bool,
    }))
  }),
};

export default CSSModules(MenuLayout, styles, { allowMultiple: true });
