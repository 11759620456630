import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import PageLinks from './PageLink';
import SectionLink from './SectionLink';
import BurgerLink from './BurgerLink';
import styles from './styles.module.css';

class NavigationLinks extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      open: false,
      showSubPageLink: null,
      active: null,
      sectionIds: [],
      lastInNav: false,
      isOverlay: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBurgerMenuClick = this.handleBurgerMenuClick.bind(this);
    this.scrollToSection = this.scrollToSection.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleMenuLinkClick = this.handleMenuLinkClick.bind(this);

  }

  componentDidMount() {

    const sectionIds = [];
    let lastInNav = false;
    if (this.props.sections && this.props.sections[0].data[0].link_to_sections === true) {

      this.props.sections.forEach((section, index) => {

        if (section.show_in_main_navigation === true) {

          sectionIds.push(section._id);
          if (index === this.props.sections.length - 1) {

            lastInNav = true;

          }

        }

      });

    }

    let index;
    if (typeof document !== 'undefined' && this.props.link_to_pages === true) {

      this.props.links.every((link, i) => {

        if (
          (link.item.path === document.location.pathname) ||
          (link.item.path === '/' && link.item.path === document.location.pathname)
        ) {

          index = i;

        }

        return link.item.path !== document.location.pathname;

      });

    }

    window.addEventListener('scroll', this.handleScroll, true);

    this.setState({
      active: (this.props.links.length > 0 && index !== undefined ) ? this.props.links[index].item._id : null,
      sectionIds,
      lastInNav,
    });

  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.matches !== this.props.matches) {

      this.handleChange(false, nextProps.matches);

    }

  }

  componentWillUnmount() {

    window.removeEventListener('scroll', this.handleScroll);

  }

  handleChange(open, mtchs) {

    const matches = mtchs || this.props.matches;
    const nav = document.getElementById('NavigationBarAnchor');

    if (!open || matches) {

      document.documentElement.style.removeProperty('overflow');
      nav.style.removeProperty('position');
      nav.style.removeProperty('top');
      nav.style.removeProperty('bottom');

    } else if (!matches) {

      const pos = window.getComputedStyle(nav, null).position;

      if (nav.className.includes('sectionNavbar') && pos !== 'absolute') {

        nav.style.position = 'absolute';
        nav.style.top = 0;
        nav.style.bottom = 0;
        document.documentElement.style.overflow = 'hidden';
        window.scrollTo(0, 0);

      } else if (nav.className.includes('sectionNavbarOverlay')) {

        nav.style.bottom = 0;
        nav.style.top = 0;
        this.setState({
          isOverlay: true,
        });

      }

    }

    this.setState({
      open,
    });

  }

  scrollToSection(event, element) {

    event.preventDefault();
    if (this.props.link_to_sections) {

      const scrollTop = document && document.documentElement ? document.documentElement.scrollTop : 0;
      const eleTop = document.getElementById(`#${element}`).getBoundingClientRect().top;
      const scrollOffset = window.pageYOffset || scrollTop;
      const offset = 12;
      const target = eleTop + scrollOffset + offset;
      window.scrollTo({
        top: target,
        behavior: 'smooth',
      });

      this.handleChange(false);

    }

  }

  handleBurgerMenuClick(id) {

    this.setState({
      active: id,
    });

    this.handleChange(false);

  }

  handleLinkClick(id) {

    if (id !== this.state.active) {

      this.setState({
        active: id,
      });

    }

  }

  handleScroll() {

    if (this.state.sectionIds.length > 0) {

      let index;
      const scrollPos = window.pageYOffset;
      this.state.sectionIds.every((id, i) => {

        let cont = true;

        const currentSection = document.getElementById(`${id}_backgroundSection`);
        if (currentSection) {

          const positions = currentSection.getBoundingClientRect();
          if (scrollPos > (positions.top + scrollPos) && scrollPos < (positions.bottom + scrollPos)) {

            index = i;
            cont = false;

          }

          if (
            this.state.lastInNav &&
            (window.innerHeight + window.scrollY) >= document.body.scrollHeight
          ) {

            index = this.state.sectionIds.length - 1;

          }

        }

        return cont;

      });

      if (index !== undefined && this.props.links[index].item._id !== this.state.active) {

        this.setState({
          active: this.props.links[index].item._id,
        });

      } else if (index === undefined) {

        this.setState({
          active: null,
        });

      }

    }

  }

  handleMenuLinkClick(event) {

    if (this.state.showSubPageLink === event) {

      this.setState({
        showSubPageLink: null,
      });

    } else {

      this.setState({
        showSubPageLink: event,
      });

    }

  }

    render() {

    let elements = [];
    const burger = [];
    let isBurger = false;
    const left = [];
    const right = [];
    this.props.links.forEach((link, index) => {

      let result;
      if (
        (this.props.link_to_pages || this.props.link_to_sections) &&
        (this.props.links.length <= this.props.burgerBreak && this.props.matches) &&
        this.props.link_to_pages
      ) {

        result = (<PageLinks
          link={link}
          color={link.item._id === this.state.active ? this.props.color0 : this.props.color}
          onLinkClick={this.handleLinkClick}
          key={`NavLink_${link.item._id}`}
          onMenuLinkClick={this.handleMenuLinkClick}
          showSubPageLink={this.state.showSubPageLink}
        />);

      } else if (
        (this.props.link_to_pages || this.props.link_to_sections) &&
        (this.props.links.length <= this.props.burgerBreak && this.props.matches) &&
        this.props.link_to_sections
      ) {

        result = (<SectionLink
          link={link}
          recColor={
            link.item._id === this.state.active ?
              this.props.color0 : this.props.color
          }
          scrollToSection={this.scrollToSection}
          key={`NavLink_${link.item._id}`}
        />);

      } else {

        isBurger = true;
        if (index === 0 && this.state.open === true && this.props.matches === false) {

          const test = (
            <div key="BurgerCloseBtn" className={styles.btnWrapper}>
              <button className={styles.cancelBtn} onClick={() => this.handleChange(!this.state.open)}>
                <i style={{ color: this.props.color }} className={`entypo icon-cancel ${styles.icon}`} />
              </button>
            </div>
          );
          burger.push(test);

        }

        burger.push(<BurgerLink
          link={link}
          color={link.item._id === this.state.active ? this.props.color0 : this.props.color}
          link_to_pages={this.props.link_to_pages}
          link_to_sections={this.props.link_to_sections}
          scrollToSection={this.scrollToSection}
          onBurgerMenuClick={this.handleBurgerMenuClick}
          key={`NavigationBarLinkDiv_${link.item._id}`}
        />);

      }

      if (isBurger !== true) {

        if (this.props.layout === 'Divided') {

          if (index >= Math.floor(this.props.links.length / 2)) {

            right.push(result);

          } else {

            left.push(result);

          }

        } else {

          elements.push(result);

        }


      }

    });

    if (isBurger !== true && this.props.layout === 'Divided' && this.props.links.length > 0) {

      elements.push(<div key="navigation_divided_left" className={styles.navbarDividedLeft}>{left}</div>);
      elements.push(<div key="navigation_divided_center">{this.props.lg}</div>);
      elements.push(<div key="navigation_divided_right" className={styles.navbarDividedRight}>{right}</div>);

    }

    if (isBurger === true) {

      let style = this.props.burgerColor;
      if (this.state.open === true && this.props.matches === false) {

        const wrapper = document.getElementById('burgerWrapper');
        if (wrapper) {

          const rect = wrapper.getBoundingClientRect();

          style = {
            ...this.props.burgerColor,
            height: this.state.isOverlay ? document.body.scrollHeight : `calc(100vh + ${rect.top}px - ${rect.height}px`,
            top: `-${rect.height + 13}px`,
          };

        }

      }

      const margin = '20px 0';

      elements = (
        <div id="burgerWrapper" className={`${styles[`burgerToggle${this.state.open === true && this.props.matches === false ? 'Open' : ''}`]}`}>
          <input
            type="checkbox"
            value={this.state.open}
            defaultChecked={false}
            onClick={
              this.state.open === true
              && this.props.matches === false
              ? undefined : () => this.handleChange(!this.state.open)
            }
            disabled={
              this.state.open === true
              && this.props.matches === false
              ? 'disabled' : false
            }
            className={styles.burgerInput}
          />
          <span className={styles.burgerLine} style={{ background: this.props.color }} />
          <span className={styles.burgerLine} style={{ background: this.props.color }} />
          <span className={styles.burgerLine} style={{ background: this.props.color }} />
          <ul className={styles[`burgerMenu${this.props.layout}`]} style={style}>
            <div style={{ margin }}>
              { burger }
            </div>
          </ul>
        </div>
      );

    }

    return (
      <nav
        className={styles[this.props.menuStyle]}
        style={isBurger ? { minWidth: '33px' } : undefined}
      >
        { elements }
      </nav>
    );

  }

}

NavigationLinks.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
      link_to_sections: PropTypes.bool,
    })),
  })),
  links: PropTypes.arrayOf(PropTypes.shape({
    item: PropTypes.shape({
      _id: PropTypes.string,
    }),
  })),
  burgerBreak: PropTypes.number,
  burgerColor: PropTypes.shape({}),
  color: PropTypes.string,
  color0: PropTypes.string,
  menuStyle: PropTypes.string,
  layout: PropTypes.string,
  link_to_pages: PropTypes.bool,
  link_to_sections: PropTypes.bool,
  lg: PropTypes.element,
  matches: PropTypes.bool,
};

export default (CSSModules(
  NavigationLinks,
  styles,
  { allowMultiple: true },
));
