import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import styles from './styles.module.css';

const SectionLink = ({
  domain,
  link,
  recColor,
  scrollToSection,
  preview,
}) => {

  const target = link.item.path;

  const element = (
    <div
      className={`PageLink ${styles.menuItem}`}
      key={`NavigationBar_${link.item._id}`}
    >
      <Link
        to={target}
        className={`${styles.menuLink} ${styles.Link}`}
        style={{ color: recColor }}
        role="button"
        tabIndex="0"
        onKeyPress={event => scrollToSection(event, link.item._id)}
        onClick={event => scrollToSection(event, link.item._id)}
      >
        {link.item.title}
      </Link>
    </div>

  );

  return element;

};

SectionLink.propTypes = {
  link: PropTypes.shape({}),
  recColor: PropTypes.string,
  scrollToSection: PropTypes.func,
};

export default CSSModules(SectionLink, styles, { allowMultiple: true });
