const IMAGEFORMATS = require('../../../api/imageFormats.json').imageFormats;

const helper = {

  normalize: (str) => {

    if (str.normalize) {

      return str.normalize('NFC');

    }

    const unorm = require('unorm');
    return unorm.nfc(str);

  },

  getBestQuality: (imageId, IMAGEDATA) => {

    const image = helper.getImageById(imageId, IMAGEDATA);

    if (image && image.path && image.sizes) {

      const ret = helper.getImgSrc(image.path, image.sizes[image.sizes.length - 1], IMAGEDATA);

      return ret;

    }

    return '';

  },


  getImageById: (id, IMAGEDATA) => {

    for (let i = 0; IMAGEDATA && i < IMAGEDATA.length; i += 1) {

      if (id === IMAGEDATA[i]._id) {

        return IMAGEDATA[i];

      }

    }

    return null;

  },

  getImgSrc: (path, format) => {

    let imgsrc = path;
    const fmt = format || 'sm';

    if (imgsrc && imgsrc.startsWith('/images/')) {

      imgsrc = imgsrc.replace('/images/', '/');

    }

    if (!imgsrc || imgsrc.startsWith('/unsplash/')
      || imgsrc.startsWith('/stock/')) {

      return imgsrc;

    }

    const n = imgsrc.lastIndexOf('.');

    let imageExt = '';

    if ((n > 0) && (n > imgsrc.lastIndexOf('/'))) {

      imageExt = imgsrc.substr(n);
      imgsrc = imgsrc.substr(0, n);

    }

    if (imgsrc.startsWith('unsplash/')) {

      return encodeURI(helper.normalize(`${process.env.IMAGES_CDN}/${imgsrc}-${fmt}${imageExt}`));

    }

    if (imgsrc.startsWith('stock/')) {

      return encodeURI(helper.normalize(`${process.env.IMAGES_CDN}/${imgsrc}-${fmt}${imageExt}`));

    }

    if (!imgsrc.startsWith('/') && !imgsrc.startsWith('http')) {

      return encodeURI(helper.normalize(`${process.env.IMAGES_CDN}/${imgsrc}-${fmt}${imageExt}`));

    }

    if (imgsrc.startsWith('/')) {

      return encodeURI(helper.normalize(`${process.env.IMAGES_CDN}${imgsrc}-${fmt}${imageExt}`));

    }

    return encodeURI(helper.normalize(`${imgsrc}-${fmt}${imageExt}`));

  },

  getFormats: (image) => {

    const width = image.originalWidth || 260;
    const height = image.originalHeight || 260;

    const formats = [];
    const ret = [];

    if (image.sizes) {

      IMAGEFORMATS.forEach((format) => {

        if (image.sizes.indexOf(format.name) > -1) {

          formats.push(format);

        }

      });

    }

    let cont = true;

    // eslint-disable-next-line
    for (let i = 0; i < formats.length && cont; i++) {

      if (width <= formats[i].width && height <= formats[i].height) {

        cont = false;

      }

      const format = formats[i];

      let h = height;
      let w = width;

      if (height / format.height >= width / format.width) {

        w = Math.round(width * (format.height / height));
        h = format.height;

      } else {

        h = Math.round(height * (format.width / width));
        w = format.width;

      }

      const fmt = {
        name: format.name,
        width: w,
        height: h,
      };

      ret.push(fmt);

    }

    return ret;

  },

  getSrcSet: (image) => {

    let imgsrc = image.path;

    if (!imgsrc
      || imgsrc.startsWith('/images/stock/')
      || imgsrc.startsWith('http')) {

      return '';

    }

    const n = imgsrc.lastIndexOf('.');
    let imageExt = '';

    if ((n > 0) && (n > imgsrc.lastIndexOf('/'))) {

      imageExt = imgsrc.substr(n);
      imgsrc = imgsrc.substr(0, n);

    }

    imgsrc = `${process.env.IMAGES_CDN}/${imgsrc}`;

    let ret = '';

    const formats = helper.getFormats(image);

    formats.forEach((format, index) => {

      const imageSrcAndExt = encodeURI(helper.normalize(`${imgsrc}-${format.name}${imageExt}`));

      ret = ret.concat(`${imageSrcAndExt} ${format.width}w`);
      if (index + 1 < formats.length) ret = ret.concat(', ');

    });

    return ret;

  },

  constructParallaxImage: (imageId, imageUrl, CDNLink, imageAlt, images, stockImages) => {

    let image;

    if (imageId && imageId.length > 0) {

      image = helper.getImageById(imageId, images, stockImages);

    }

    let url;
    if (imageUrl && !imageUrl.startsWith('stock/')) (url = imageUrl);
    else url = CDNLink;

    if (!image && url) {

      image = {
        path: url,
        alt: imageAlt || 'static',
      };

    }

    if (!image) {

      // TODO very good place for "broken image" image here
      return null;

    }

    return image;

  },

};

export default helper;
