import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../helper';
import styles from './styles.module.css';

class ButtonEditor extends React.Component {

  static fontSizeSwitch(size) {

    switch (size) {

      case 'Small':
        return '0.85rem';

      case 'Normal':
        return '1rem';

      case 'Large':
        return '1.25rem';

      default:
        return '1rem';

    }

  }

  render() {

    let bColorHex = this.props.themeData.colors[2];
    if (this.props.data.styles.border.active && this.props.data.styles.border.color !== '') {

      bColorHex = this.props.data.styles.border.color;

    }

    const bColor = formColor(
      { solid: bColorHex },
      false,
      this.props.data.styles.border.opacity,
    );

    const defaultThickness = this.props.button.includes('Secondary') ? '1px' : 0;

    const bThickness = this.props.data.styles.border.active === true ?
      `${this.props.data.styles.border.thickness}px` : defaultThickness;
    const border = `${bColor.backgroundColor} solid ${bThickness}`;
    let borderRadius = this.props.data.styles.border.active === true ?
      `${this.props.data.styles.border.radius}px` : `${this.props.themeData.button.radius}px`;
    const x = this.props.data.styles.shadow.active ? this.props.data.styles.shadow.x : 0;
    const y = this.props.data.styles.shadow.active ? this.props.data.styles.shadow.y : 0;
    const blur = this.props.data.styles.shadow.active ? this.props.data.styles.shadow.blur : 0;
    const spread = this.props.data.styles.shadow.active ? this.props.data.styles.shadow.spread : 0;

    if (this.props.fullWidth) {

      borderRadius = '';

    }

    let sColor;
    if (this.props.data.styles.shadow.active) {

      let shadow = this.props.data.styles.shadow.color;
      if (this.props.data.styles.shadow.color === '') {

        [, , shadow] = this.props.themeData.colors;

      }

      sColor = formColor(
        { solid: shadow },
        false,
        this.props.data.styles.shadow.opacity,
      );

    }

    const boxShadow = this.props.data.styles.shadow.active ?
      `${x}px ${y}px ${blur}px ${spread}px ${sColor.backgroundColor}` : undefined;
    const fontFamily = this.props.themeData.typography.button.name;
    const fontSize = ButtonEditor.fontSizeSwitch(this.props.themeData.typography.button.fontSize);
    const textTransform = this.props.themeData.typography.button.textTransform;

    const style = {
      borderRadius,
      border,
      boxShadow,
      fontFamily,
      fontSize,
      textTransform,
    };

    let font = this.props.data.styles.fontColor.color;
    if (this.props.data.styles.fontColor.color === '') {

      font = this.props.button.includes('Secondary') ? this.props.themeData.colors[0] : '#FFF';

    }

    const fontColor = formColor(
      { solid: font },
      undefined,
      this.props.data.styles.fontColor.opacity,
    );

    style.color = fontColor.backgroundColor;

    let { backgroundColor } = this.props.data.styles;
    if (this.props.data.styles.backgroundColor.gradient.from === '' && this.props.data.styles.backgroundColor.solid === '') {

      backgroundColor = this.props.button.includes('Secondary') ? { solid: '#FFF' } : { solid: this.props.themeData.colors[0] };

    }

    const bgColor = formColor(
      backgroundColor,
      false,
      this.props.data.styles.backgroundColor.opacity,
    );

    if (bgColor.background) {

      style.background = bgColor.background;

    } else {

      style.backgroundColor = bgColor.backgroundColor;

    }

    let icon;
    if (this.props.data.icon.active === true) {

      icon = (
        <span
          className={`entypo ${this.props.data.icon.class}`}
          aria-hidden
          style={
            {
              marginRight: this.props.data.label ? '16px' : '0',
              fontSize: this.props.data.icon.size,
              color: this.props.data.icon.color !== '' ? this.props.data.icon.color : '#000000',
            }
          }
        />
      );

    }

    const button = (
      <button
        className={`${styles.Button}${this.props.fullWidth === true ? ` ${styles.full}` : ''}${this.props.styleN ? ` ${styles[this.props.styleN]}` : ''}`}
        style={style}
      >
        { icon }
        {HTMLParser(this.props.data.label)}
      </button>
    );

    const isLink = !!this.props.data.link;
    const isLocal = isLink && (this.props.data.link.startsWith(('/')) || this.props.data.link.startsWith(('#')));
    const previewTarget = this.props.data.tab ? '_blank' : '_self';

    let result = button;
    if (isLink && isLocal) {

      result = (
        <Link
          target={previewTarget}
          rel="noopener noreferrer"
          to={this.props.data.link}
          className={this.props.fullWidth === true ? `${styles.width100}` : ''}
        >
          { button }
        </Link>
      );

    } else if (isLink && !isLocal) {

      result = (
        <a
          href={this.props.data.link}
          target={previewTarget}
          rel="noopener noreferrer"
          className={this.props.fullWidth === true ? `${styles.width100}` : ''}
        >
          { button }
        </a>
      )

    }

    return result;

  }

}

ButtonEditor.propTypes = {
  fullWidth: PropTypes.bool,
  data: PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
    styles: PropTypes.shape({
      backgroundColor: PropTypes.shape({
        opacity: PropTypes.number,
        solid: PropTypes.string,
        gradient: PropTypes.shape({
          from: PropTypes.string,
          to: PropTypes.string,
        }),
      }),
      fontColor: PropTypes.shape({
        color: PropTypes.string,
        opacity: PropTypes.number,
      }),
      radius: PropTypes.number,
      shadow: PropTypes.shape({
        active: PropTypes.bool,
        color: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
        blur: PropTypes.number,
        spread: PropTypes.number,
        opacity: PropTypes.number,
      }),
      border: PropTypes.shape({
        active: PropTypes.bool,
        color: PropTypes.string,
        thickness: PropTypes.number,
        opacity: PropTypes.number,
        radius: PropTypes.number,
      }),
    }),
  }),
  button: PropTypes.string,
};

export default CSSModules(ButtonEditor, styles);
