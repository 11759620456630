import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import styles from './styles.module.css';
import Image from '../../../Image';

class SectionMedia extends React.PureComponent {

  render() {

    const iconStyle = this.props.iconStyle || '';
    const align = styles.alignCenter;

    let id;

    if (this.props && this.props.data) {

      id = this.props.data.id;

    }

    const isLink = !!this.props.data.linkUrl;
    const isLocal = isLink && (this.props.data.linkUrl.startsWith(('/')) || this.props.data.linkUrl.startsWith(('#')));
    const previewTarget = this.props.data.openLinkInNewTab ? '_blank' : '_self';

    const mediaElement = (this.props.mediaType === 'IMAGE')
      ? (
        <Image
          alt={this.props.alt}
          id={id}
          url={this.props.src}
          componentIndex={this.props.componentIndex}
          elementIndex={this.props.elementIndex}
          imageClass={this.props.elementStyle}
          data-mediatype={this.props.mediaType}
          mediaType={this.props.mediaType}
          sizes={this.props.sizes || '100vw'}
          onClick={this.props.onClick}
          images={this.props.images}
        />
      )
      : (
        <div
          className={`${this.props.align === undefined ? align : styles[`align${this.props.align}`]} ${styles.iconWrapper} ${styles[iconStyle]}`}
        >
          <span
            className={`entypo ${this.props.data.icon.class}`}
            style={
              {
                fontSize: this.props.data.icon.size,
                color: this.props.data.icon.color,
              }
            }
          />
        </div>
      );

    return (
      <div className={`${this.props.align ? styles[`align${this.props.align}`] : align} ${styles[this.props.wrapperStyle]}`}>
        {
          isLink && isLocal
            ? (
              <Link target={previewTarget} rel="noopener noreferrer" to={this.props.data.linkUrl}>
                {mediaElement}
              </Link>
            )
            : isLink && !isLocal
              ? (
              <a target={previewTarget} rel="noopener noreferrer" href={this.props.data.linkUrl}>
                {mediaElement}
              </a>
            )
            : (mediaElement)
        }
      </div>
    );

  }

}

SectionMedia.propTypes = {
  align: PropTypes.string,
  mediaType: PropTypes.string,
  elementStyle: PropTypes.string,
  wrapperStyle: PropTypes.string,
  iconStyle: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  data: PropTypes.shape({
    linkUrl: PropTypes.string,
    openLinkInNewTab: PropTypes.bool,
    id: PropTypes.string,
    icon: PropTypes.shape({
      size: PropTypes.string,
      color: PropTypes.string,
      class: PropTypes.string,
      alt: PropTypes.string,
    }),
  }),
  onClick: PropTypes.func,
};

export default (CSSModules(
  SectionMedia, styles,
  { allowMultiple: true },
));
