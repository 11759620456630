import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';
import '../../../../lightbox.css';
import styles from './styles.module.css';

let Lightbox;
if (typeof window !== 'undefined') {

  Lightbox = require('lightbox-react').default;

}

class VideoLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    this.state = {
      section: props.section,
      layout: props.section.data[props.section.data.length - 1].layout,
      videoID: props.section.data[2].videoID,
      autoplay: props.section.data[2].autoplay,
      enableTimeCode: props.section.data[2].enableTimeCode,
      minutes: Number(props.section.data[2].minutes),
      seconds: Number(props.section.data[2].seconds),
      isOpen: false,
    };

    this.handleClick = this.handleClick.bind(this);

  }

  handleClick(value) {

    this.setState({
      isOpen: value,
    });

  }

  render() {

    const cols = (this.state.layout !== 'box' && this.state.layout !== 'center' && this.state.layout !== 'full') ? 'col-12 col-md-6' : 'col-12';
    let videoUrl = '';
    let timeCode = Number(this.props.minutes * 60);
    timeCode += Number(this.props.seconds);

    if (this.state.enableTimeCode) {

      videoUrl = `https://www.youtube.com/embed/${this.state.videoID}?autoplay=${this.state.autoplay}&start=${timeCode}`;

    } else {

      videoUrl = `https://www.youtube.com/embed/${this.state.videoID}?autoplay=${this.state.autoplay}`;

    }

    /* eslint-disable jsx-a11y/iframe-has-title */
    const videoBase = (
      <iframe
        frameBorder="0"
        className={styles.videoIframeStyle}
        src={videoUrl}
        allowFullScreen
        aria-hidden="true"
      />
    );
    /* eslint-enable jsx-a11y/iframe-has-title */

    let video;
    let btn;
    if (this.state.layout === 'box') {

      btn = (
        <div className={styles.btnWrapper}>
          <button
            onClick={() => this.handleClick(true)}
            className={styles.btn}
            style={{ backgroundColor: this.props.themeData.colors[0] }}
          >
            <i aria-hidden="true" className={`entypo icon-play ${styles.icon}`} />
            { this.state.section.data[2].btnText }
          </button>
        </div>
      );

      if (this.state.isOpen === true && typeof window !== 'undefined') {

        video = (
          <Lightbox
            mainSrc={videoBase}
            onCloseRequest={() => this.handleClick(false)}
          />
        );

      }

    } else {

      video = (
        <div className={`${cols} ${styles.videoFrameWrapper}`}>
          { videoBase }
        </div>
      );

    }

    return (
      <div className={this.state.layout === 'full' ? styles.noPadding : 'container'}>
        <div className={`row no-gutters ${this.state.layout === 'right' ? styles.videoLayoutRight : ''}`}>
          {
            (
              (
                (this.state.section.data[0].active || this.state.section.data[1].active) &&
                (this.state.layout === 'box' || this.state.layout === 'center' || this.state.layout === 'full')
              ) ||
              (this.state.layout !== 'box' && this.state.layout !== 'center' && this.state.layout !== 'full')
            ) &&
            (
              <div className={`${cols} ${styles.videoInnerWrapper}`}>
                <div className={styles.videoInnerInnerWrapper}>
                  <div className={styles.videoText}>
                    {
                      this.state.section.data[0].active &&
                      (
                        HTMLParser(`<h2>${this.state.section.data[0].data}</h2>`)
                      )
                    }

                  </div>
                  <div className={styles.videoText}>
                    {
                      this.state.section.data[1].active &&
                      (
                        HTMLParser(`<span>${this.state.section.data[1].data}</span>`)
                      )
                    }
                  </div>
                  { btn }
                </div>
              </div>
            )
          }
          { video }
        </div>
      </div>
    );

  }

}

VideoLayout.propTypes = {
  section: PropTypes.shape({}),
};

export default CSSModules(VideoLayout, styles, { allowMultiple: true });
