import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';
import axios from 'axios';
import Reaptcha from 'reaptcha';

import { formColor, hexToRgb } from '../../../../helper';
import ButtonEditor from '../../ButtonEditor';
import SectionMedia from '../SectionMedia';
import SimpleCheckbox from './SimpleCheckbox';
import SimpleRadioButton from './SimpleRadioButton';
import styles from './styles.module.css';

const emailServiceURL = process.env.CONTACTFORM_LAMBDA_URL;

class ContactLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const index = props.section.data.length - 1;
    const align = props.section.data[index].align !== undefined ? props.section.data[index].align : 'Left';

    this.state = {
      section: props.section,
      align,
      formSent: false,
      formError: false,
      waitingResponse: false,
      recaptcha: '',
      verified: false,
      showCaptcha: false,
      checkRequiredFields: false,
      layout: props.section.data[index].layout,
      alertText: props.section.data[props.section.data.length - 3].checkRequiredFieldsMessage,
    };

    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.onVerify = this.onVerify.bind(this);
    this.createInput = this.createInput.bind(this);
    this.createTitles = this.createTitles.bind(this);
    this.createSendButton = this.createSendButton.bind(this);
    this.createAlert = this.createAlert.bind(this);

  }

  onVerify(token) {

    this.setState({

      verified: true,
      recaptcha: token,

    });

  }

  handleInputFocus() {

    this.setState({

      showCaptcha: true,

    });

  }

  handleSubmitForm() {

    const activeInputs = [];
    const requestInputs = [];
    let validEmail = false;
    let error = false;

    this.setState({

      showCaptcha: true,

    });

    // eslint-disable-next-line
    const validateEmailRegex = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/;

    // Loop through all active inputs

    this.state.section.data.map((component, index) => {

      if ((component.type === 'COMPONENT/PERMANENT' || component.type === 'COMPONENT') && component.active) {

        if (((component.data[1].type === 'FORM/INPUT') ||
          (component.data[1].type === 'FORM/INPUT/TEXT') ||
          (component.data[1].type === 'FORM/INPUT/RADIO') ||
          (component.data[1].type === 'FORM/INPUT/CHECKBOX') ||
          (component.data[1].type === 'FORM/INPUT/DROPDOWN') ||
          (component.data[1].type === 'FORM/INPUT/PHONENUMBER') ||
          (component.data[1].type === 'FORM/INPUT/EMAIL') ||
          (component.data[1].type === 'FORM/INPUT/TEXTAREA')) && component.data[1].active) {

          const cleanText = component.data[0].data.replace(/<\/?[^>]+(>|$)/g, '');
          // eslint-disable-next-line
          component.data[1].text = cleanText;
          // eslint-disable-next-line
          component.data[1].index = index;
          activeInputs.push(component.data[1]);

        }

      }

      return null;

    });

    this.setState({

      checkRequiredFields: false,

    });


    // Check ReCaptcha is filled

    if (!this.state.verified) {

      const captchaElement = document.querySelector(`#recaptcha_${this.state.section._id}`);
      const alertElement = document.querySelector(`#check_fields_${this.state.section._id}`);
      alertElement.scrollIntoView();
      if (captchaElement) {

        const col = hexToRgb('#cd5849', 0.3);
        captchaElement.style.boxShadow = `0px 0px 16px 1px rgba(${col.r}, ${col.g}, ${col.b}, ${col.a})`;

        this.setState({

          checkRequiredFields: true,
          alertText: this.state.section.data[this.state.section.data.length - 3].checkRecaptchaMessage,

        });

      }

    } else {

      const captchaElement = document.querySelector(`#recaptcha_${this.state.section._id}`);
      captchaElement.style.boxShadow = 'none';

      this.setState({

        checkRequiredFields: false,

      });

    }

    activeInputs.map((input) => {

      let inputItem;
      let inputElement;

      if (input.category === 'radio') {

        const radioEl = document.querySelector(`input[name="radioOptionGroup_${input.index}_${this.state.section._id}"]:checked`);
        const radioValue = radioEl ? radioEl.value : null;

        if (radioValue) {

          inputItem = {
            label: input.text,
            value: radioValue,
            format: input.format,
            required: input.required,
            category: input.category,
          };

          const inputLabel = document.querySelector(`#label_${input.category}_${this.state.section._id}`);
          inputElement = document.getElementById(`radioOptionGroup_${input.index}_${this.state.section._id}`);
          inputLabel.style.color = this.props.themeData.colors[3];
          inputLabel.style.fontWeight = this.props.themeData.typography.default.weight;
          inputElement.style.boxShadow = 'none';

        } else if (input.required === true) {

          const inputLabel = document.querySelector(`#label_${input.category}_${this.state.section._id}`);
          const col = hexToRgb('#cd5849', 0.3);
          if (inputLabel) {

            inputLabel.style.color = '#cd5849';
            inputLabel.style.fontWeight = 'bold';

          }

          inputElement = document.getElementById(`radioOptionGroup_${input.index}_${this.state.section._id}`);
          const alertElement = document.querySelector(`#check_fields_${this.state.section._id}`);
          alertElement.scrollIntoView();
          inputElement.style.boxShadow = `0px 0px 16px 1px rgba(${col.r}, ${col.g}, ${col.b}, ${col.a})`;
          error = true;
          this.setState({

            checkRequiredFields: true,
            alertText: this.state.section.data[this.state.section.data.length - 3].checkRequiredFieldsMessage,

          });

        }

      } else if (input.category === 'checkbox') {

        // Get nodelist of checkboxes on this section
        const checkboxValue = document.querySelectorAll(`input[name="checkboxOptionGroup_${input.index}_${this.state.section._id}"]`);
        // Make the list into an array
        const checkList = [...checkboxValue];
        // Filter the checked one
        const checked = checkList.filter(alkio => alkio.checked === true);
        // Push only the values into a new array
        const checkedData = [];

        checked.forEach((data) => {

          checkedData.push(data.value);

        });

        console.log('checkbox input: ', input);
        console.log('checkedData: ', checkedData);
        console.log('checkboxValue: ', checkboxValue);

        if (checkboxValue && checkedData.length > 0) {

          inputItem = {
            label: input.text,
            value: checkedData,
            format: input.format,
            required: input.required,
            category: input.category,
          };

          const inputLabel = document.querySelector(`#label_${input.category}_${this.state.section._id}`);
          inputElement = document.getElementById(`checkboxOptionGroup_${input.index}_${this.state.section._id}`);
          // eslint-disable-next-line
          inputLabel.style.color = this.props.themeData.colors[3];
          inputLabel.style.fontWeight = this.props.themeData.typography.default.weight;
          inputElement.style.boxShadow = 'none';

        } else if ((checkedData.length < 1) && (input.required === true)) {

          console.log('checkbox required and empty boxes');

          const inputLabel = document.querySelector(`#label_${input.category}_${this.state.section._id}`);
          const col = hexToRgb('#cd5849', 0.3);
          if (inputLabel) {

            inputLabel.style.color = '#cd5849';
            inputLabel.style.fontWeight = 'bold';

          }

          inputElement = document.getElementById(`checkboxOptionGroup_${input.index}_${this.state.section._id}`);
          const alertElement = document.querySelector(`#check_fields_${this.state.section._id}`);
          alertElement.scrollIntoView();
          inputElement.style.boxShadow = `0px 0px 16px 1px rgba(${col.r}, ${col.g}, ${col.b}, ${col.a})`;
          error = true;
          this.setState({

            checkRequiredFields: true,
            alertText: this.state.section.data[this.state.section.data.length - 3].checkRequiredFieldsMessage,

          });


        }

      } else if (input.category === 'dropdown') {

        console.log('dropDown input.index: ', input);

        const dropdownEl = document.getElementById(`dropdownOptionGroup_${input.index}_${this.state.section._id}`);

        console.log('dd input: ', input);
        console.log('dropDownEl: ', dropdownEl);
        const dropdownValue = dropdownEl.options[dropdownEl.selectedIndex].value || '';
        console.log('ddValue: ', dropdownValue);

        console.log('dd input: ', input);


        if (dropdownValue) {

          inputItem = {
            label: input.text,
            value: dropdownValue,
            format: input.format,
            required: input.required,
            category: input.category,
          };

          const inputLabel = document.querySelector(`#label_${input.category}_${this.state.section._id}`);
          inputElement = document.getElementById(`dropdownOptionGroup_${input.index}_${this.state.section._id}`);
          // eslint-disable-next-line
          inputLabel.style.color = this.props.themeData.colors[3];
          inputLabel.style.fontWeight = this.props.themeData.typography.default.weight;
          inputElement.style.boxShadow = 'none';

        } else if (input.required === true) {

          console.log('required dd but not filled');

          const inputLabel = document.querySelector(`#label_${input.category}_${this.state.section._id}`);
          const col = hexToRgb('#cd5849', 0.3);
          if (inputLabel) {

            inputLabel.style.color = '#cd5849';
            inputLabel.style.fontWeight = 'bold';

          }

          inputElement = document.getElementById(`dropdownOptionGroup_${input.index}_${this.state.section._id}`);
          const alertElement = document.querySelector(`#check_fields_${this.state.section._id}`);
          alertElement.scrollIntoView();
          inputElement.style.boxShadow = `0px 0px 16px 1px rgba(${col.r}, ${col.g}, ${col.b}, ${col.a})`;
          error = true;
          this.setState({

            checkRequiredFields: true,
            alertText: this.state.section.data[this.state.section.data.length - 3].checkRequiredFieldsMessage,

          });

        }

      } else {

        inputElement = document.querySelector(`#form_${input.category}_${this.state.section._id}`);

        inputItem = {
          label: input.text,
          value: inputElement.value || '',
          format: input.format,
          required: input.required,
          category: input.category,
        };

      }

      if (input.format === 'email') {

        validEmail = validateEmailRegex.test(inputElement.value);

      }

      if ((input.required) && (input.category !== 'radio') && (input.category !== 'checkbox') && (input.category !== 'dropdown')) {

        if ((inputElement.value === '') && ((inputElement.format !== 'email')
          || (inputElement.category !== 'radio')
          || (inputElement.category !== 'dropdown')
          || (inputElement.category !== 'checkbox'))) {

          const inputLabel = document.querySelector(`#label_${input.category}_${this.state.section._id}`);
          const col = hexToRgb('#cd5849', 0.3);
          if (inputLabel) {

            inputLabel.style.color = '#cd5849';
            inputLabel.style.fontWeight = 'bold';

          }

          const alertElement = document.querySelector(`#check_fields_${this.state.section._id}`);
          alertElement.scrollIntoView();
          inputElement.style.boxShadow = `0px 0px 16px 1px rgba(${col.r}, ${col.g}, ${col.b}, ${col.a})`;
          error = true;
          this.setState({

            checkRequiredFields: true,
            alertText: this.state.section.data[this.state.section.data.length - 3].checkRequiredFieldsMessage,

          });

        }

        if (input.format === 'email' && !validEmail) {

          const inputLabel = document.querySelector(`#label_${input.category}_${this.state.section._id}`);
          const col = hexToRgb('#cd5849', 0.3);

          if (inputLabel) {

            inputLabel.style.color = '#cd5849';
            inputLabel.style.fontWeight = 'bold';

          }
          const alertElement = document.querySelector(`#check_fields_${this.state.section._id}`);
          alertElement.scrollIntoView();
          inputElement.style.boxShadow = `0px 0px 16px 1px rgba(${col.r}, ${col.g}, ${col.b}, ${col.a})`;
          error = true;

          this.setState({

            checkRequiredFields: true,
            alertText: this.state.section.data[this.state.section.data.length - 3].checkEmailMessage,

          });

        }

        if (!(input.format === 'email' && !validEmail) && !(inputElement.value === '' && inputElement.format !== 'email')) {

          const inputLabel = document.querySelector(`#label_${input.category}_${this.state.section._id}`);
          if (inputLabel) {

            // eslint-disable-next-line
            inputLabel.style.color = this.props.themeData.colors[3];
            inputLabel.style.fontWeight = this.props.themeData.typography.default.weight;

          }
          inputElement.style.boxShadow = 'none';

        }

      }

      requestInputs.push(inputItem);

      return null;

    });

    if (this.state.verified && !error) {

      const {subjectTimeStamp} = this.state.section.data[this.state.section.data.length - 3] || false;
      const {receiverEmail} = this.state.section.data[this.state.section.data.length - 3];
      const validReceiverEmail = validateEmailRegex.test(receiverEmail);

      if (!validReceiverEmail) {

        const alertElement = document.querySelector(`#check_fields_${this.state.section._id}`);
        alertElement.scrollIntoView();
        error = true;

        this.setState({

          checkRequiredFields: true,
          alertText: this.state.section.data[this.state.section.data.length - 3].checkReceiverEmailMessage,

        });

      } else {

        this.setState({

          checkRequiredFields: false,

        });

        /* eslint-disable */

        const requestBody = {

          "inputs": requestInputs,
          "g-recaptcha-response": this.state.recaptcha,
          "receiverEmail": receiverEmail,
          "subjectTimeStamp": subjectTimeStamp,
          "defaultSubject": this.state.section.data[this.state.section.data.length - 3].defaultSubject,

        }

        /* eslint-enable */

        const formButton = document.querySelector(`#formButton_${this.state.section._id}`);
        const form = document.querySelector(`#form_${this.state.section._id}`);
        const formResponse = document.querySelector(`#formResponse_${this.state.section._id}`);

        const requestBodyString = (JSON.stringify(requestBody));
        const headers = {headers: {'Content-Type': 'application/json'}};
        const emailInstance = axios.create({

          withCredentials: false,

        });

        this.setState({

          waitingResponse: true,

        });

        emailInstance.post(emailServiceURL, JSON.stringify(requestBodyString), headers)
          .then((res) => {

            if (res.data.statusCode === 200) {

              if (formButton) {

                formButton.style.display = 'none';

              }
              form.style.display = 'none';

              this.setState({

                formSent: true,
                formError: false,
                waitingResponse: false,

              }, () => {

                const formSection = document.getElementById(`#${this.state.section._id}`);
                formSection.scrollIntoView({block: 'start', inline: 'nearest', behaviour: 'smooth'});

              });

            } else {

              if (formButton) {

                formButton.style.display = 'none';

              }
              formResponse.innerHTML = this.state.section.data[this.state.section.data.length - 3].errorMessage;
              this.setState({

                formSent: true,
                formError: true,
                waitingResponse: false,

              }, () => {

                const formSection = document.getElementById(`#${this.state.section._id}`);
                formSection.scrollIntoView({block: 'start', inline: 'nearest', behaviour: 'smooth'});

              });

            }

          })
          .catch((err) => {

            console.error(err);
            this.setState({

              formSent: true,
              formError: true,
              waitingResponse: false,

            });

          });

      }

    }

  }

  createInput(item, index, labelStyle, formStyle) {

    let col;

    if (this.state.layout === 'design2') {

      if ((item.item.data[1].type === 'FORM/INPUT/TEXTAREA')
        || (item.item.data[1].type === 'IMAGES/IMAGE')) {

        col = 'col-12';

      } else {

        col = 'col-6';

      }

    } else if (this.state.layout === 'left' || this.state.layout === 'design3') {

      col = '';

    }

    const dataIndex = item.item.data[1] ? 1 : 0;

    return (
      <div
        key={`${this.state.section._id}_layout_${index}`}
        className={`${col} ${styles.contactFormLabel} ${styles[`align${item.item.align}`]}`}
        style={formStyle}
      >
        <label htmlFor={`form_${item.item.data[dataIndex].category}_${this.state.section._id}`}>
          <div
            className={`${styles[`Body${this.props.themeData.typography.default.fontSize}`]} ${styles.contactFormText} ${styles.inputField} noEvents textItem_${this.state.section._id}_${index}0`}
            id={`label_${item.item.data[dataIndex].category}_${this.state.section._id}`}
            style={labelStyle}
          >
            {
              ((item.item.data[0].active) && (dataIndex === 1)) && (
                <div className={styles.inputField}>
                  {
                    HTMLParser(`<p>${item.item.data[0].data}${item.item.data[1].required ? '<span> *</span>' : ''}</p>`)
                  }
                </div>
              )
            }
          </div>
          {
            item.item.data[dataIndex].active &&
            item.item.data[dataIndex].type === 'FORM/INPUT' &&
            <input
              id={`form_${item.item.data[dataIndex].category}_${this.state.section._id}`}
              name={item.item.data[dataIndex].category}
              type={item.item.data[dataIndex].format}
              className={`noEvents ${styles[`contactFormInput${this.props.themeData.typography.default.fontSize}`]}`}
              onFocus={this.handleInputFocus}
              tabIndex={index + 1}
              placeholder={item.item.data[dataIndex].placeholder}
              required
            />
          }
          {
            item.item.data[dataIndex].active &&
            item.item.data[dataIndex].type === 'FORM/INPUT/TEXT' &&
            <input
              id={`form_${item.item.data[dataIndex].category}_${this.state.section._id}`}
              name={`textinput_${index}`}
              required
              onFocus={this.handleInputFocus}
              className={`noEvents ${styles[`contactFormInput${this.props.themeData.typography.default.fontSize}`]}`}
              tabIndex={index + 1}
              placeholder={item.item.data[dataIndex].placeholder}
            />
          }
          {
            item.item.data[dataIndex].active &&
            item.item.data[dataIndex].type === 'FORM/INPUT/TEXTAREA' &&
            <textarea
              id={`form_${item.item.data[dataIndex].category}_${this.state.section._id}`}
              name="content"
              required
              onFocus={this.handleInputFocus}
              className={`noEvents ${styles[`contactFormTextarea${this.props.themeData.typography.default.fontSize}`]}`}
              tabIndex={index + 1}
              placeholder={item.item.data[dataIndex].placeholder}
            />
          }
          {
            item.item.data[dataIndex].active &&
            item.item.data[dataIndex].type === 'FORM/INPUT/EMAIL' &&
            <input
              id={`form_${item.item.data[dataIndex].category}_${this.state.section._id}`}
              name={`textarea_${index}`}
              type="email"
              required
              onFocus={this.handleInputFocus}
              className={`noEvents ${styles[`contactFormInput${this.props.themeData.typography.default.fontSize}`]}`}
              tabIndex={index + 1}
              placeholder={item.item.data[dataIndex].placeholder}
            />
          }
          {
            item.item.data[dataIndex].active &&
            item.item.data[dataIndex].type === 'FORM/INPUT/PHONENUMBER' &&
            <input
              id={`form_${item.item.data[dataIndex].category}_${this.state.section._id}`}
              name={`textarea_${index}`}
              type="tel"
              required
              onFocus={this.handleInputFocus}
              className={`noEvents ${styles[`contactFormInput${this.props.themeData.typography.default.fontSize}`]}`}
              tabIndex={index + 1}
              placeholder={item.item.data[dataIndex].placeholder}
            />
          }
          {
            item.item.data[dataIndex].active &&
            item.item.data[dataIndex].type === 'IMAGES/IMAGE' &&
            (<SectionMedia
              key={`image_${index}_${this.state.section._id}`}
              mediaType={item.item.data[dataIndex].data.icon ? 'ICON' : 'IMAGE'}
              wrapperStyle="contactImageWrapper"
              elementStyle="contactImage"
              elementIndex={item.index}
              src={item.item.data[dataIndex].data.CDNLink ?
                item.item.data[dataIndex].data.CDNLink :
                `${process.env.IMAGES_URL}/${item.item.data[dataIndex].data.src}`}
              alt={item.item.data[dataIndex].data.alt}
              data={item.item.data[dataIndex].data}
            />)
          }
          {
            item.item.data[dataIndex].active &&
            item.item.data[dataIndex].type === 'FORM/INPUT/RADIO' &&
            (
              <div
                id={`radioOptionGroup_${item.index}_${this.state.section._id}`}
                className={item.item.data[dataIndex].flex === 'column' ? styles.inputFlexColumn : styles.inputFlexRow}
              >
                {item.item.data[dataIndex].data.map((opt, idx) =>
                  (
                    <SimpleRadioButton
                      key={`radioOptionGroup_${item.index}_input_${idx}`}
                      value={opt}
                      index={item.index}
                      idx={idx}
                      sectionId={this.state.section._id}
                      flex={item.item.data[dataIndex].flex}
                    />
                  ))}
              </div>)
          }
          {
            item.item.data[dataIndex].active &&
            item.item.data[dataIndex].type === 'FORM/INPUT/CHECKBOX' &&
            (
              <div
                className={item.item.data[dataIndex].flex === 'column' ? styles.inputFlexColumn : styles.inputFlexRow}
                id={`checkboxOptionGroup_${item.index}_${this.state.section._id}`}
              >
                {item.item.data[dataIndex].data.map((opt, idx) =>

                  (
                    <SimpleCheckbox
                      key={`checkboxOptionGroup_${item.index}_input_${idx}`}
                      value={opt}
                      index={item.index}
                      idx={idx}
                      sectionId={this.state.section._id}
                      flex={item.item.data[dataIndex].flex}
                    />
                  ))}
              </div>)
          }
          {
            /* eslint-disable */
            item.item.data[dataIndex].active &&
            item.item.data[dataIndex].type === 'FORM/INPUT/DROPDOWN' &&
            (
              <select
                className={styles.select}
                id={`dropdownOptionGroup_${item.index}_${this.state.section._id}`}
              >
                {item.item.data[dataIndex].data.map((opt, idx) =>

                  (
                    <option
                      key={`dropDownOptionGroup_${item.index}_input_${idx}`}
                      id={`dropDownOptionGroup_${item.index}_input_${idx}`}
                      name={`dropDownOptionGroup_${item.index}_input${idx}`}
                      className={styles.inputOption}
                      value={opt}
                      data-index={item.index}
                    >
                      {opt}
                    </option>
                  ),
                )}
              </select>
            )

            /* eslint-enable */
          }
          {
            item.item.data[dataIndex].active &&
            item.item.data[dataIndex].type === 'FORM/SUBTITLE' &&
            (
              <div
                id={`${this.state.section._id}_Paragraph_0_section`}
                className={`noEvents textItem_${this.state.section._id}_1 ${styles[`Subtitle2${this.props.themeData.typography.default.fontSize}`]} ${styles.contactFormSubtitle}`}
                style={labelStyle}
              >
                {
                  HTMLParser(`<h3>${item.item.data[dataIndex].data}</h3>`)
                }
              </div>
            )
          }
          {
            item.item.data[dataIndex].active &&
            item.item.data[dataIndex].type === 'FORM/PARAGRAPH' &&
            (
              <div
                id={`${this.state.section._id}_Paragraph_0_section`}
                className={`noEvents textItem_${this.state.section._id}_1 ${styles[`Body${this.props.themeData.typography.default.fontSize}`]} ${styles.contactFormParagraph}`}
                style={labelStyle}
              >
                {
                  HTMLParser(`<p>${item.item.data[dataIndex].data}</p>`)
                }
              </div>
            )
          }
        </label>
      </div>);

  }

  createTitles(alignStyle, titleStyle, defaultStyle, alertStyle) {

    const title2Style = `Title2${this.props.themeData.typography.heading.fontSize}`;
    const bodyStyle = `Body${this.props.themeData.typography.default.fontSize}`;

    const title = (
      <div
        key={`${this.state.section._id}_Title_0_section`}
        style={{display: 'flex', flexDirection: 'column', flexWrap: 'nowrap'}}
      >
        {
          this.state.section.data[0].active &&
          <div
            className={`${styles[title2Style]} ${styles.contactFormHeader}`}
            style={titleStyle}
          >
            {HTMLParser(`<h2>${this.state.section.data[0].data}</h2>`)}
          </div>

        }
        {
          this.state.section.data[1].active &&
          <div
            className={`${styles[bodyStyle]} ${styles.contactFormParagraph}`}
            style={defaultStyle}
          >
            {HTMLParser(`<p>${this.state.section.data[1].data}</p>`)}
          </div>
        }
        {
          (this.state.layout === 'design2' || this.state.layout === 'design3') &&
          <React.Fragment>
            {this.createAlert(alertStyle)}
          </React.Fragment>
        }
      </div>
    );

    return title;

  }

  createSendButton(themeButtonStyle, responseStyle) {

    const button = (
      <React.Fragment
        key={`formButton_${this.state.section._id}`}
      >
        {
          this.state.section.data[this.state.section.data.length - 2].active &&
          !this.state.waitingResponse && !this.state.formSent &&
          <div
            id={`formButton_${this.state.section._id}`}
            className={`${styles.contactBtnWrapper}`}
            style={themeButtonStyle}
          >
            <div onClick={this.handleSubmitForm}>
              <ButtonEditor
                button="Secondary"
                data={this.state.section.data[this.state.section.data.length - 2].data}
                themeData={this.props.themeData}
              />
            </div>
          </div>
        }
        {this.state.waitingResponse &&
        <div>
          <p className={styles.sending}><span>.</span><span>.</span><span>.</span></p>
        </div>
        }
        <p
          id={`formResponse_${this.state.section._id}`}
          style={responseStyle}
        >
          {
            (this.state.formSent && !this.state.waitingResponse && !this.state.formError)
            &&
            <React.Fragment>
              {this.state.section.data[this.state.section.data.length - 3].successMessage}
            </React.Fragment>
          }
        </p>
      </React.Fragment>
    );

    return button;

  }

  createAlert(alertStyle) {

    const alert = (
      <div
        key={`check_fields_${this.state.section._id}`}
        id={`check_fields_${this.state.section._id}`}
        style={alertStyle}
      >
        <span>
          {this.state.alertText}
        </span>
      </div>
    );

    return alert;

  }

  render() {

    const title2Style = `Title2${this.props.themeData.typography.heading.fontSize}`;
    const bodyStyle = `Body${this.props.themeData.typography.default.fontSize}`;

    const themeHeadingStyle = {

      fontFamily: this.props.themeData.typography.heading.name,
      fontWeight: this.props.themeData.typography.heading.weight,
      lineHeight: this.props.themeData.typography.heading.lineHeight,
      letterSpacing: this.props.themeData.typography.heading.letterSpacing,
      textTransform: this.props.themeData.typography.heading.textTransform,

    };

    const themeDefaultStyle = {

      fontFamily: this.props.themeData.typography.default.name,
      fontWeight: this.props.themeData.typography.default.weight,
      lineHeight: this.props.themeData.typography.default.lineHeight,
      letterSpacing: this.props.themeData.typography.default.letterSpacing,

    };

    const themeButtonStyle = {

      fontFamily: this.props.themeData.typography.button.name,
      fontWeight: this.props.themeData.typography.button.weight,
      lineHeight: this.props.themeData.typography.button.lineHeight,
      letterSpacing: this.props.themeData.typography.button.letterSpacing,
      textTransform: this.props.themeData.typography.button.textTransform,

    };

    const color3 = {
      color: this.props.themeData.colors[3],
    };

    const activeComponents = [];

    this.state.section.data.map((item, index) => {

      if ((item.type === 'COMPONENT/PERMANENT' || item.type === 'COMPONENT') && item.active) {

        activeComponents.push({item, index});

      }

      return null;

    });

    const alignStyle = `align${this.state.align}`;

    let responseFontSize = '1rem';

    if (this.props.themeData.typography.default.fontSize === 'Small') {

      responseFontSize = '0.85rem';

    }

    if (this.props.themeData.typography.default.fontSize === 'Large') {

      responseFontSize = '1.25rem';

    }

    let alertStyle;

    if (this.state.checkRequiredFields) {

      alertStyle = {

        backgroundColor: '#cd5849',
        borderRadius: '4px',
        fontSize: responseFontSize,
        padding: '16px',
        width: '100%',
        height: '85px',
        color: 'white',
        display: 'flex',
        marginTop: '26px',
        marginBottom: '23px',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 'bold',

      };


    } else {

      alertStyle = {

        display: 'none',

      };

    }

    let responseStyle;

    if (this.state.formSent) {

      if (this.state.formError) {

        responseStyle = {

          backgroundColor: '#cd5849',
          borderRadius: '4px',
          fontSize: responseFontSize,
          padding: '30px',
          color: 'white',
          display: 'inline-block',
          fontWeight: 'bold',

        };

      } else {

        responseStyle = {

          backgroundColor: 'forestgreen',
          borderRadius: '4px',
          fontSize: responseFontSize,
          padding: '30px',
          color: 'white',
          display: 'inline-block',
          fontWeight: 'bold',
          marginTop: '40px',

        };

      }

    } else {

      responseStyle = {

        display: 'none',

      };

    }

    const col1 = [];
    const col2 = [];

    const titleStyle = {...themeHeadingStyle, ...color3};
    const defaultStyle = {...themeDefaultStyle, ...color3};
    const paddingLeft = this.state.layout === 'design2' ? {paddingLeft: '15px'} : {};
    const extraPadding = this.props.layout === 'left' ? { paddingLeft: '30px', paddingRight: '30px' } : {};

    const wrapperColumns = this.state.layout === 'design3' ? 'col-10' : 'col-12 col-md-8';
    let titleAdded = false;

    const formRow = this.state.layout !== 'left' ? 'row' : '';

    const reqFields = (
      <div
        key={`required_fields_${this.state.section._id}`}
        id={`required_fields_${this.state.section._id}`}
        className={`${styles[bodyStyle]} ${styles.contactRequiredFields}`}
        style={{...themeDefaultStyle, ...color3, ...paddingLeft}}
      >
        <span className={styles.alignLeft}>
          * {this.state.section.data[this.state.section.data.length - 3].requiredField}
        </span>
      </div>
    );

    const recaptcha = (
      <div
        key={`recaptcha_${this.state.section._id}`}
        id={`recaptcha_${this.state.section._id}`}
        className={`${styles.captchaWrapper}`}
        style={{display: this.state.showCaptcha ? 'flex' : 'none'}}
      >
        <Reaptcha
          sitekey={process.env.RECAPTCHA_SITE_KEY}
          onVerify={this.onVerify}
        />
      </div>
    );


    return (
      <div className="container">
        <div className={`row ${styles[alignStyle]}`}>
          <div
            className={`${wrapperColumns} ${styles.contactFormWrapper}`}
            style={extraPadding}
          >
            {
              this.state.layout !== 'design3' &&
              this.createTitles(alignStyle, titleStyle, defaultStyle, alertStyle)
            }
            {
              this.state.layout === 'left' &&
              this.createAlert(alertStyle)
            }
            {
              (this.state.formSent &&
                !this.state.formError &&
                !this.state.waitingResponse)
              && this.createSendButton(themeButtonStyle, responseStyle)
            }
            <form
              id={`form_${this.state.section._id}`}
              className={`${styles.contactForm} ${formRow}`}
              style={this.state.layout === 'design2' ? {justifyContent: 'flex-start'} : {}}
            >
              {
                activeComponents.length > 0 &&
                <React.Fragment>
                  {
                    activeComponents.map((item, index) => {

                      let style;
                      if (item.item.styles.backgroundColor.active) {

                        let {backgroundColor} = item.item.styles;
                        if (
                          item.item.styles.backgroundColor.solid === '' &&
                          item.item.styles.backgroundColor.gradient.from === ''
                        ) {

                          backgroundColor = {solid: '#ffffff'};

                        }

                        style = formColor(
                          backgroundColor,
                          false,
                          item.item.styles.backgroundColor.opacity,
                        );

                      }

                      const input = this.createInput(
                        item,
                        index,
                        {...themeDefaultStyle, ...color3},
                        style,
                      );

                      if (this.state.layout === 'left') {

                        col1.push(input);

                        if (index === activeComponents.length - 1) {

                          col1.push(reqFields);

                        }

                        if (activeComponents.length === 0) {

                          col1.push(reqFields);
                          col1.push(recaptcha);

                        }

                      } else if (this.state.layout === 'design2') {

                        if ((index % 2) === 0) {

                          col1.push(input);

                        } else {

                          col2.push(input);

                        }

                        if (index === activeComponents.length - 1) {

                          col2.push(reqFields);

                        }

                      } else if (this.state.layout === 'design3') {

                        if (!titleAdded) {

                          const title = this.createTitles(
                            alignStyle,
                            titleStyle,
                            defaultStyle,
                            alertStyle,
                          );
                          col1.push(title);
                          titleAdded = true;

                        }

                        if (index === 0) {

                          const topMargin = (
                            <div style={{
                              display: 'block',
                              paddingTop: '64px',
                            }}
                            >
                              {input}
                            </div>
                          );

                          col2.push(topMargin);

                        } else {

                          col2.push(input);

                        }

                        if (index === activeComponents.length - 1) {

                          col2.push(reqFields);
                          col2.push(recaptcha);
                          col2.push(this.createSendButton(themeButtonStyle, responseStyle));

                        }

                      }

                      return null;

                    })

                  }

                </React.Fragment>
              }
              {
                this.state.layout === 'left' &&
                (
                  <React.Fragment>
                    {col1.length > 0 &&
                    (
                      <React.Fragment>
                        {col1}
                      </React.Fragment>
                    )
                    }
                    {recaptcha}
                    {this.createSendButton(themeButtonStyle, responseStyle)}
                  </React.Fragment>
                )
              }
              {
                this.state.layout === 'design2' &&
                (
                  <React.Fragment>
                    {col1}
                    {col2}
                    {recaptcha}
                    {this.createSendButton(themeButtonStyle, responseStyle)}
                  </React.Fragment>
                )
              }
              {
                this.state.layout === 'design3' && (
                  <React.Fragment>
                    <div className="col-6">
                      {col1}
                    </div>
                    <div className="col-6">
                      {col2}
                    </div>
                  </React.Fragment>
                )
              }
            </form>
          </div>
        </div>
      </div>
    );

  }

}

ContactLayout.propTypes = {
  align: PropTypes.string,
  section: PropTypes.shape({
    _id: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
      checkRequiredFieldsMessage: PropTypes.string,
    })),
  }),
  themeData: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.string),
    typography: PropTypes.shape({
      default: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      heading: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      button: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
    }),
  }),
};

export default CSSModules(
  ContactLayout, styles,
  { allowMultiple: true },
);
