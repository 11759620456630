import React from 'react';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';
import Masonry from 'react-masonry-css';
import '../../../../lightbox.css';
import styles from './styles.module.css';
import Image from '../../../Image';

import imageHelper from '../../../Image/helper';

let Lightbox;
if (typeof window !== 'undefined') {

  Lightbox = require('react-image-lightbox').default;

}

class GalleryLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const breakpointColumnsObj = {
      default: 4,
      1150: 3,
      700: 2,
      500: 1,
    };

    const index = this.props.section.data.length - 1;
    const align = this.props.section.data[index].align !== undefined ?
      this.props.section.data[index].align : 'Left';
    const full = this.props.section.data[index].full === true ? 'Full' : '';
    const layout = this.props.section.data[index].layout;
    const images = [];
    this.props.section.data.forEach(element => element.type === 'IMAGES/IMAGE' &&
      element.active === true && images.push(element));

    this.state = {
      section: props.section,
      images,
      layout,
      align,
      full,
      breakpointColumnsObj,
      photoIndex: 0,
      isOpen: false,
    };

    this.handleImageClick = this.handleImageClick.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleMoveNextRequest = this.handleMoveNextRequest.bind(this);
    this.handleMovePrevRequest = this.handleMovePrevRequest.bind(this);

  }

  handleImageClick(event) {

    this.setState({
      photoIndex: event.target.dataset.index,
      isOpen: true,
    });

  }

  handleCloseClick() {

    this.setState({
      isOpen: false,
    });

  }

  handleMoveNextRequest() {

    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
    });

  }

  handleMovePrevRequest() {

    this.setState({
      photoIndex: (this.state.photoIndex + (this.state.images.length - 1))
        % this.state.images.length,
    });

  }

  render() {

    const headerLayout = `galleryHeader${this.state.layout.charAt(0).toUpperCase()}${this.state.layout.slice(1)}`;
    const alignStyle = `align${this.state.align}`;

    const sizes = '' +
      '(max-width: 768px) 100vw, ' +
      '(min-width: 768px and max-width: 992px) 50vw, ' +
      '(min-width: 992px and max-width: 1200px) 33vw, ' +
      '(min-width: 1200px) 25vw, ' +
      '100vw';

    return (
      <div className={`container ${styles[`galleryContainer${this.state.full}`]}`}>
        {
          this.state.isOpen && (
            <Lightbox
              mainSrc={imageHelper.getBestQuality(this.state.images[this.state.photoIndex].data.id, this.props.images)}
              nextSrc={imageHelper.getBestQuality(this.state.images[(this.state.photoIndex + 1) % this.state.images.length].data.id, this.props.images)}
              prevSrc={imageHelper.getBestQuality(this.state.images[(this.state.photoIndex + (this.state.images.length - 1)) % this.state.images.length].data.id, this.props.images)}
              onCloseRequest={this.handleCloseClick}
              onMovePrevRequest={this.handleMovePrevRequest}
              onMoveNextRequest={this.handleMoveNextRequest}
            />
          )
        }
        <div className={`col-12 ${styles[headerLayout]} ${styles[alignStyle]}`}>
          {
            this.state.section.data[0].active &&
            (
              HTMLParser(`<h2>${this.state.section.data[0].data}</h2>`)
            )
          }
        </div>
        <div className={`row ${styles.galleryRowWrapper} ${styles[alignStyle]}`}>
          {
            this.state.layout !== 'masonry' &&
            (this.state.images.map((item, index) => (
              <div
                key={`${this.state.section._id}_layout_${index}`}
                className={`col-12 col-sm-6 col-md-4 col-lg-3 ${this.state.layout === 'gutters' ?
                  styles.galleryGuttersImage
                  :
                  styles.galleryNoGuttersImage}`}
              >
                <div className={styles.galleryImageWrapper}>
                  <Image
                    id={item.data.id}
                    url={item.data.src}
                    alt={item.data.alt}
                    imageClass="galleryImage"
                    dataIndex={index}
                    onClick={this.handleImageClick}
                    sizes={sizes}
                    images={this.props.images}
                  />
                </div>
              </div>
            )))

          }
          {
            this.state.layout === 'masonry' &&
            (
              <Masonry
                breakpointCols={this.state.breakpointColumnsObj}
                style={{ display: 'flex' }}
              >
                {
                  this.state.images.map((item, index) => (
                    <div
                      className={styles.galleryMasonryImageWrapper}
                      key={`${item.data.src}_${index}`}
                    >
                      <Image
                        id={item.data.id}
                        url={item.data.src}
                        alt={item.data.alt}
                        imageClass={'galleryMasonryImage'}
                        dataIndex={index}
                        onClick={this.handleImageClick}
                        sizes={sizes}
                        images={this.props.images}
                      />
                    </div>
                  ))
                }
              </Masonry>
            )
          }
        </div>
      </div>
    );

  }

}

export default CSSModules(GalleryLayout, styles, { allowMultiple: true });
