import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../helper';
import SectionMedia from '../SectionMedia';
import ButtonEditor from '../../ButtonEditor';
import styles from './styles.module.css';

class FeaturesLayout extends React.Component {

  constructor(props) {

    super(props);

    const index = props.section.data.length - 1;
    const align = props.section.data[index].align !== undefined ? props.section.data[index].align : 'Left';
    const full = props.section.data[index].full === true ? 'Full' : '';
    const layout = props.section.data[index].layout;

    this.state = {
      section: props.section,
      full,
      align,
      layout,
    };

  }

  render() {

    const activeComponents = [];
    this.state.section.data.map((item, index) => {

      if (item.type === 'COMPONENT' && item.active) {

        activeComponents.push({ item, index });

      }

      return null;

    });

    const columns = `col-12 col-md-6${this.state.layout === 'left' ? ' col-lg-3' : ''}`;

    const features = [];
    this.state.section.data.map((item, index) => {

      let imgElem;
      let buttonElem;
      let textElem = [];

      if (item.type === 'COMPONENT' && item.active) {

        const elems = [];
        const texts = [];
        item.data.forEach((elem, i) => {

          if (this.state.layout === 'left') {

            if (elem.active) {

              if (elem.type === 'IMAGES/IMAGE') {

                elems.push(<SectionMedia
                  key={`imageItem_${this.state.section._id}_${index}${i}`}
                  mediaType={(this.state.section.data[index].data[0].data.icon) ? 'ICON' : 'IMAGE'}
                  wrapperStyle="featuresImageWrapper"
                  elementStyle="featuresImage"
                  iconStyle="featuresImage"
                  align={item.align}
                  src={`${process.env.IMAGES_CDN}/${this.state.section.data[index].data[0].data.src}`}
                  alt={this.state.section.data[index].data[0].data.alt}
                  data={this.state.section.data[index].data[0].data}
                  images={this.props.images}
                />);

              } else if (elem.type.startsWith('BUTTONS/')) {

                const value = (
                  <div
                    key={`${this.state.section._id}_layout_${index}${i}`}
                    className={`${styles.btnWrapper} ${styles[`align${item.align}`]}`}
                  >
                    <ButtonEditor
                      themeData={this.props.themeData}
                      button="Button"
                      data={elem.data}
                    />
                  </div>
                );

                elems.push(value);

              } else if (elem.type.startsWith('PARAGRAPH/') || elem.type.startsWith('HEADINGS/')) {

                let style;
                let text;

                if (elem.type.startsWith('PARAGRAPH/')) {

                  style = styles.featuresComponentParagraph;
                  text = `<span>${elem.data}</span>`;

                } else {

                  style = styles.featuresComponentHeader;
                  text = `<h3>${elem.data}</h3>`;

                }

                const value = (
                  <div
                    key={`textItem_${this.state.section._id}_${index}${i}`}
                    className={style}
                  >
                    {HTMLParser(text)}
                  </div>
                );

                texts.push(value);
                if (
                  !item.data[i + 1].active
                  || (!item.data[i + 1].type.startsWith('PARAGRAPH/') && !item.data[i + 1].type.startsWith('HEADINGS/'))
                ) {

                  elems.push(<div className={styles[`alignColumn${item.align}`]}>{texts}</div>);

                }

              }

            }

          } else if (this.state.layout === 'cards') {

            if (elem.active) {

              if (elem.type === 'IMAGES/IMAGE') {

                imgElem = (<SectionMedia
                  key={`imageItem_${this.state.section._id}_${index}${i}`}
                  mediaType={(this.state.section.data[index].data[0].data.icon) ? 'ICON' : 'IMAGE'}
                  wrapperStyle="featuresImageWrapperCards"
                  elementStyle="featuresImageCards"
                  iconStyle="featuresImage"
                  align={item.align}
                  src={`${process.env.IMAGES_CDN}/${this.state.section.data[index].data[0].data.src}`}
                  alt={this.state.section.data[index].data[0].data.alt}
                  data={this.state.section.data[index].data[0].data}
                  images={this.props.images}
                />);

              } else if (elem.type.startsWith('BUTTONS/')) {

                buttonElem = (
                  <div
                    key={`${this.state.section._id}_layout_${index}${i}`}
                    className={`${styles.btnWrapperCards} ${styles[`align${item.align}`]}`}
                  >
                    <ButtonEditor
                      themeData={this.props.themeData}
                      button="Button"
                      data={elem.data}
                    />
                  </div>
                );

              } else if (elem.type.startsWith('PARAGRAPH/') || elem.type.startsWith('HEADINGS/')) {

                let style;
                let inlineStyle;
                let text;

                if (elem.type.startsWith('PARAGRAPH/')) {

                  style = `${styles.featuresComponentParagraph}`;
                  text = `<span>${elem.data}</span>`;

                } else {

                  style = `${styles.featuresComponentHeader}`;
                  text = `<h3>${elem.data}</h3>`;

                }

                const textElement = (
                  <div
                    key={`textItem_${this.state.section._id}_${index}${i}`}
                    className={style}
                  >
                    {HTMLParser(text)}
                  </div>
                );

                textElem.push(textElement);

              }

            }

          }

        });

        let style;
        if (this.state.section.data[index].styles.backgroundColor.active) {

          let {backgroundColor} = this.state.section.data[index].styles;
          if (
            this.state.section.data[index].styles.backgroundColor.solid === '' &&
            this.state.section.data[index].styles.backgroundColor.gradient.from === ''
          ) {

            backgroundColor = {solid: '#ffffff'};

          }

          style = formColor(
            backgroundColor,
            false,
            this.state.section.data[index].styles.backgroundColor.opacity,
          );

        }

        if (style !== undefined) {

          style.paddingTop = '15px';
          style.paddingBottom = '15px';

        }

        if (this.state.layout === 'left') {

          const result = (
            <div
              key={`${this.state.section._id}_layout_${index}`}
              className={`${columns} ${styles.featuresComponentWrapper} ${styles[`alignColumn${item.align}`]}`}
              style={style}
            >
              {elems}
            </div>
          );

          features.push(result);

        } else if (this.state.layout === 'cards') {

          // tabIndex here for component onBlur to function
          const result = (
            <div
              id={`${this.state.section._id}_Component_${index}_section`}
              tabIndex={0}
              key={`${this.state.section._id}_layout_${index}`}
              className={`${columns} ${styles.featuresComponentWrapper} align${item.align}`}
              style={style}
            >
              <div className={`row no-gutters ${styles.featuresComponentWrapperRow}`}>
                <div className="col-12 col-md-5">
                  {imgElem}
                </div>
                <div className={`col-12 col-md-7 ${styles.featuresBlockWrapper}`}>
                  <div className={styles.featuresTextBlockWrapper}>
                    {textElem}
                    {buttonElem}
                  </div>
                </div>
              </div>
            </div>
          );

          features.push(result);

          textElem = [];

        }

        return null;

      }

    });

    const alignStyle = `align${this.state.align}`;

    return (
      <div className={`container ${styles[`featuresContainer${this.state.full}`]}`}>
        <div className={`row ${styles[alignStyle]}`}>
          <div className={`col-12 ${styles.featuresMainHeader}`}>
            {
              this.state.section.data[0].active &&
              (
                HTMLParser(`<h2>${this.state.section.data[0].data}</h2>`)
              )
            }
          </div>
          <div className={`col-12 ${styles.featuresSubHeader}`}>
            {
              this.state.section.data[1].active &&
              (
                <h4 style={{ fontStyle: 'normal', fontStretch: 'normal', lineHeight: 1.5, fontWeight: 'normal' }}>
                  {HTMLParser(this.state.section.data[1].data)}
                </h4>
              )
            }
          </div>
          { features }
        </div>
      </div>
    );

  }

}

FeaturesLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      align: PropTypes.string,
      full: PropTypes.bool,
    })),
  }),
};

export default CSSModules(FeaturesLayout, styles, { allowMultiple: true });
