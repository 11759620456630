import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../helper'
import ButtonEditor from '../../ButtonEditor';
import styles from './styles.module.css';

class HeroLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const index = props.section.data.length - 1;
    const align = props.section.data[index].align !== undefined ? props.section.data[index].align : 'Left';

    this.state = {
      section: props.section,
      align,
      index,
    };

  }

  render() {

    const headerAlignStyle = `heroHeader${this.state.align}`;
    const paragraphAlignStyle = `heroParagraph${this.state.align}`;
    const buttonAlignStyle = `heroBtnWrapper${this.state.align}`;
    const heroExceptionStyle = `heroException${this.props.themeData.typography.default.fontSize}`;
    const btnWrapper = this.props.layout === 'design3' ? styles.overlayBtnWrapper : styles[buttonAlignStyle];

    let style;
    if (this.props.layout === 'design4' || this.props.layout === 'design3') {

      let backgroundColor = { solid: '#ffffff' };
      let opacity = 1;

      if (
        this.state.section.data[this.state.index].styles.bg
        && this.state.section.data[this.state.index].styles.bg.active
        && this.state.section.data[this.state.index].styles.bg.solid
      ) {

        backgroundColor = { solid: this.state.section.data[this.state.index].styles.bg.solid };
        ({ opacity } = this.state.section.data[this.state.index].styles.bg);

      }

      style = formColor(
        backgroundColor,
        false,
        opacity,
      );

    }

    let btn;
    const texts = [];
    this.state.section.data.forEach((item, index) => {

      if (item.active) {

        let type;
        let text;
        if (item.type.startsWith('HEADINGS') || item.type.startsWith('PARAGRAPH')) {

          if (item.type === 'HEADINGS/HEADING-ONE') {

            text = (<h1>{HTMLParser(item.data)}</h1>);
            type = 'h1';

          } else if (item.type === 'PARAGRAPH/PARAGRAPH') {

            text = (
              <span
                style={{color: this.props.themeData.colors[3]}}
                className={styles[heroExceptionStyle]}
              >
                {HTMLParser(item.data)}
              </span>
            );
            type = 'span';

          }

        }

        if (text) {

          const res = (

            <div
              key={`${this.state.section._id}_layout_${index}`}
              className={
                type === 'h1'
                  ?
                  styles[headerAlignStyle]
                  :
                  styles[paragraphAlignStyle]}

            >
              { text }
            </div>

          );
          texts.push(res);

        } else if (item.type.startsWith('BUTTON')) {

          btn = (

            <div
              key={`${this.state.section._id}_layout_${index}`}
              className={btnWrapper}
              style={this.props.layout === 'design3' ? style : undefined}
            >
              <ButtonEditor
                themeData={this.props.themeData}
                button="Button"
                data={item.data}
                styleN={this.props.layout === 'design3' ? 'design3Pos' : ''}
              />
            </div>

          );

        }

      }

      return null;

    });

    let design4;
    if (this.props.layout === 'design4') {

      design4 = (
        <div className={styles.design4} style={style} />
      );

    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-10 offset-sm-1">
            { texts }
            {
              this.props.layout !== 'design3'
              && (btn)
            }
          </div>
          {
            this.props.layout === 'design3'
            && (btn)
          }
          { design4 }
        </div>
      </div>
    );

  }

}

HeroLayout.propTypes = {
  themeData: PropTypes.shape({}),
  section: PropTypes.shape({}),
};

export default CSSModules(HeroLayout, styles, { allowMultiple: true });
