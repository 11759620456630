import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import styles from './styles.module.css';

class BurgerLink extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      open: false,
      showSubPageLink: false,
    };


    this.clickMenuLink = this.clickMenuLink.bind(this);
    this.subMenuClick = this.subMenuClick.bind(this);

  }

  subMenuClick() {

    this.setState({
      showSubPageLink: null,
    });

  }

  handleBurgerMenuClick() {

    this.setState({
      open: !this.state.open,
    });

  }

  clickMenuLink(event) {

    if (this.state.showSubPageLink === event) {

      this.setState({
        showSubPageLink: null,
      });

    } else {

      this.setState({
        showSubPageLink: event,
      });

    }

  }

  render() {

    if (
      this.props.link.item.show_in_main_navigation === true ||
      this.props.link.item.show_in_main_navigation === undefined
    ) {

      let target;
      if (this.props.link_to_pages) {

        target = this.props.link.item.path;

      } else if (this.props.link_to_sections) {

        target = `${this.props.link.item.path}#${this.props.link.item._id}`;

      }

      let style;
      if (this.props.link_to_pages === true && this.props.link.item.subpageLevel > 0) {

        style = {
          fontSize: '0.75rem',
        };

      }

      return (
        <span className="PageLink">
          <Link
            to={target}
            className={`${styles.menuLink} ${styles.Link}`}
            role="button"
            tabIndex="0"
            onKeyPress={this.props.link_to_sections
              ? event => this.props.scrollToSection(event, this.props.link.item._id)
              : () => this.props.onBurgerMenuClick(this.props.link.item._id)
            }
            onClick={this.props.link_to_sections
              ? event => this.props.scrollToSection(event, this.props.link.item._id)
              : () => this.props.onBurgerMenuClick(this.props.link.item._id)
            }
          >
            <li
              style={{ color: this.props.color, ...style }}
              className={styles[`burgerItem${this.props.link_to_pages === true && this.props.link.item.subpageLevel > 0 ? 'Small' : ''}`]}
            >
              {this.props.link.item.title}
            </li>
          </Link>
        </span>
      );

    }

    return null;

  }

}

BurgerLink.propTypes = {
  color: PropTypes.string,
  link: PropTypes.shape({
    index: PropTypes.number,
    item: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      title: PropTypes.string,
      show_in_main_navigation: PropTypes.bool,
    }),
  }),
  link_to_sections: PropTypes.bool,
  link_to_pages: PropTypes.bool,
  scrollToSection: PropTypes.func,
  onBurgerMenuClick: PropTypes.func,
};

export default CSSModules(
  BurgerLink,
  styles,
  { allowMultiple: true },
);
