import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import styles from './styles.module.css';

class PageLink extends React.Component {

  constructor(props) {

    super(props);

    this.subMenuClick = this.subMenuClick.bind(this);
    this.clickMenuLink = this.clickMenuLink.bind(this);
    this.hoverMenuLink = this.hoverMenuLink.bind(this);

  }

  subMenuClick() {

    this.props.onMenuLinkClick(null);

  }

  hoverMenuLink(event, target) {

    if (event.type === 'mouseover') {

      if (event.target.closest('.PageLink') && target !== this.props.showSubPageLink) {

        this.clickMenuLink(target);

      }

    } else if (event.type === 'mouseout') {

      if (
        (!event.relatedTarget.closest('.PageLink') && !event.target.className.includes('arrow'))
      ) {

        this.clickMenuLink(target);

      }

    }

  }

  clickMenuLink(event) {

    this.props.onMenuLinkClick(event);

  }

  render() {

    const positionRelative = {
      position: 'relative',
    };

    const { link } = this.props;

    if (
      (
        link.item.show_in_main_navigation === true ||
        link.item.show_in_main_navigation === undefined
      ) &&
      link.item.subpageLevel === 0
    ) {

      const element = (
        <div
          className={`PageLink ${styles[`menuItem${link.item.children.length > 0 ? 'Sub' : ''}`]}`}
          key={`NavigationBar_${link.item._id}`}
          onMouseOver={e => this.hoverMenuLink(e, link.item._id)}
          onMouseOut={e => this.hoverMenuLink(e, link.item._id)}
        >
          <Link
            to={link.item.path}
            className={`${styles[`menu${link.item.children.length > 0 ? 'Main' : ''}Link`]} ${styles.Link}`}
            style={{
              ...positionRelative,
              color: this.props.color,
            }}
            onClick={() => this.props.onLinkClick(link.item._id)}
          >
            {link.item.title}
          </Link>
          {
            link.item.children.length > 0 &&
            (
              <span className={styles.Link}>
                <i
                  role="button"
                  tabIndex="0"
                  onKeyPress={() => this.clickMenuLink(link.item._id)}
                  onClick={() => this.clickMenuLink(link.item._id)}
                  style={{ color: this.props.color }}
                  className={styles.arrow}
                />
              </span>
            )
          }
          {
            (this.props.showSubPageLink === link.item._id && link.item.children.length > 0) &&
            (
              <ul className={styles.navbarSubLink}>
                {
                  link.item.children.map(lnk => (
                    <a
                      href={lnk.path}
                      className={`${styles.menuLink} ${styles.Link}`}
                      key={lnk.id}
                    >
                      <li
                        className={`${styles.subPageLinkColor} ${styles.subPageLink}`}
                        style={{ color: this.props.color }}
                        role="button"
                        tabIndex="0"
                        onKeyPress={this.subMenuClick}
                        onClick={this.subMenuClick}
                      >
                        {lnk.title}
                      </li>
                    </a>

                  ))
                }
              </ul>
            )
          }
        </div>
      );

      return element;

    }

    return null;

  }

}

PageLink.propTypes = {
  color: PropTypes.string,
  link: PropTypes.shape({}),
  onLinkClick: PropTypes.func,
  onMenuLinkClick: PropTypes.func,
  showSubPageLink: PropTypes.string,
};

export default CSSModules(PageLink, styles, { allowMultiple: true });
